@import '../../../styles/magazine-index';

.base {
  transition: height $duration-default, transform 1s;
  width: 100%;

  &.is-hidden {
    height: 0;
  }
}

.container {
  background-color: $color-surrogate--10;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
}
