@import '../../styles/index';

.base {
  background-color: $color-white--100;
  padding: 1.25rem 1.25rem 1.5rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 8px 48px rgba(0, 0, 0, 0.08);
  border-radius: 0.375rem;
  font-size: $font-size-16;
}

.status {
  padding: 0.5rem 0.75rem;
  line-height: 1.2;
  text-align: center;
  font-weight: $font-weight-medium;
  color: $color-violet--100;
  background-color: $color-violet--10;
  border-radius: $border-radius-default;
}

.address {
  margin: 1rem auto 0 auto;
  text-align: center;
  line-height: 1.6;
  font-weight: $font-weight-light;
  font-style: normal;

  span {
    display: block;
    font-size: $font-size-18;
  }
}

.link {
  justify-content: center;
  margin-top: 1rem;

  &-mobile {
    display: none;

    @include media-to(sm) {
      display: flex;
    }
  }

  &-desktop {
    display: none;

    @include media-from(sm) {
      display: flex;
    }
  }
}
