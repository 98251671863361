@import '../../../styles/magazine-index';

.base {
  background-color: $color-white--100;
  height: auto;
  margin: 0;
  position: sticky;
  top: 0;
  transition:
    margin $duration-default,
    background-color $duration-default;
  width: 100%;
  z-index: $index-page-header;

  &.is-scrolled-top {
    background-color: transparent;
  }
}

.top-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 3.75rem;
  justify-content: space-between;
  padding: 0 1rem;
  @include media-from(sm) {
    padding: 0 1.875rem;
  }
}

.logo {
  line-height: 0;
}

.article-title-wrapper {
  align-items: center;
  display: none;
  flex-direction: row;

  @include media-from(sm) {
    display: flex;
  }
}

.article-title {
  color: $color-black--80;
  display: none;
  font-size: 1.125rem;
  @include media-from(sm) {
    display: inline;
  }
}

.nav-button {
  background-color: transparent;
  border: 0;
  color: $color-surrogate--100;
  cursor: pointer;
  display: none;
  margin-left: auto;
  padding: 0;
  @include media-from(sm) {
    align-items: center;
    display: flex;
  }
  &:disabled {
    cursor: unset;
  }
}

.nav-button-mobile {
  align-items: center;
  background-color: #f6e8ee;
  border: none;
  border-radius: 18px;
  color: $color-surrogate--100;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-weight: $font-weight-medium;
  line-height: 1;
  padding: 10px 15px 10px 20px;
  @include media-from(sm) {
    display: none;
  }
}

.chevron,
.icon {
  height: 0.875rem;
  width: 0.875rem;
}

.icon {
  margin-left: 0.5rem;
  transform: rotate(90deg);
  transition: transform $duration-default;
}

.rotated {
  transform: rotate(270deg);
}

.icon-wrapper {
  display: flex;
}

.progress-bar {
  bottom: 0;
  transition:
    height $duration-default,
    transform 1s;
  width: 100%;
}

.height-animator {
  backdrop-filter: saturate(180%) blur(20px);
  background-color: $color-white--100;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 8px 0px inset;
  height: 0;
  left: 0;
  margin-left: auto;
  overflow-y: hidden;
  position: absolute;
  top: calc(100%);
  transition: background-color $duration-default;
  width: 100%;
  z-index: $index-header;
  &.closed {
    background-color: transparentize($color-white--100, 0.2);
  }
}

.children {
  display: flex;
  flex-direction: column;

  @include media-from(md) {
    align-items: center;
    flex-direction: row;
  }
}

.children-list {
  margin: 0;
  padding: 0.5rem 1rem;
  width: 100%;
  @include media-from(md) {
    padding: 0.75rem 0;
  }
}

.anchor-item-wrapper {
  width: 100%;
  @include media-to(md) {
    &:not(:last-child) {
      border-bottom: 1px solid $color-black--20;
    }
  }
}

.anchor-item {
  color: black;
  list-style-type: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  @include media-to(md) {
    margin: 0 1rem;
    padding: 0.75rem 0;
  }
  @include media-from(md) {
    padding: 0.5rem 1.875rem;
  }
  a {
    color: $color-black--100;
  }
  &:hover {
    color: $color-surrogate--100;
  }
  &:focus {
    outline: none;
  }
}

.overlay {
  background-color: $color-black--100;
  height: 0;
  opacity: 0;
  position: absolute;
  transition: opacity $duration-long;

  &.overlay-is-active {
    height: 100vh;
    opacity: 0.5;
    width: 100vw;
  }
}

.link {
  color: $color-black--100;
  font-size: $font-size-18;
  margin-right: 1rem;
  transition: color $duration-default;

  &:not(:first-of-type) {
    margin-left: 1rem;
  }
}

.main-link {
  background: $color-violet--100
    linear-gradient(320deg, #{$color-red--100} 0%, #{$color-violet--100} 100%);
  background-clip: text;
  -webkit-background-clip: text;
  box-decoration-break: clone;
  -webkit-box-decoration-break: clone;
  font-weight: $font-weight-medium;
  margin-left: 0;

  transition: background $duration-default;
  -webkit-text-fill-color: transparent;
}

.chevron {
  color: $color-surrogate--100;
}

.category-color {
  @include setMagazineCategoryColorFor('color');
}

.button {
  display: none;
  margin-left: auto;
  margin-right: 0;

  @include media-from(sm) {
    display: flex;
  }
}

.back-button {
  display: flex;

  @include media-from(sm) {
    display: none;
  }
}

.back-button-scrolled {
  background-color: $color-surrogate--10;
  color: $color-surrogate--100;
}

.link-button {
  all: unset;
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: 1rem;

  .icon-wrapper {
    color: $color-black--100;
  }
}

.breadcrumb {
  align-items: center;
  display: flex;
  justify-content: center;
}

.is-scrolled-top {
  .progress-bar {
    height: 0;
  }

  .link-button {
    cursor: auto;
  }

  .back-button,
  .button {
    background-color: rgba($color-white--100, 20%);
    color: $color-white--100;
  }

  .main-link {
    background: $color-white--100;
    background-clip: text;
    -webkit-background-clip: text;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone;
    -webkit-text-fill-color: transparent;
  }

  .nav-button {
    display: none;
  }

  .link {
    color: $color-white--100;
  }

  .chevron {
    color: $color-white--100;
  }
}
