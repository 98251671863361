@import '../../styles/index';

.base {
  position: relative;
}

.map-container {
  position: relative;
  width: 100%;
  min-height: 85vh;
  overflow: hidden;
  margin-bottom: 1rem;

  @include media-from(sm) {
    min-height: 800px;
  }
}

.fiber-optic-map-legend {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 24.5rem;
  bottom: 1.5rem;
  padding: 0 1rem;
}

.fiber-optic-map-details {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 1.5rem;
  max-width: 24.5rem;
  padding: 0 1rem;
}

.search-box {
  position: relative;
  background-color: $color-white--100;
  z-index: 1;

  @include media-from(sm) {
    top: 1rem;
    left: 1rem;
    position: absolute;
  }
}

.full-screen {
  $search-box-height: 5.8125rem;
  height: calc(100vh - $search-box-height);
  width: 100vw;
  margin-bottom: 0;
}
