@import '../../styles/index';

.base {
  margin: 0;
}

.banner {
  min-height: 22.5rem;
  padding: 4rem 1rem 2rem;

  border-radius: 1rem;
  background: $gradient-enercity;
  position: relative;

  @include media-from(sm) {
    padding: 0.5rem 4rem 0.5rem 4rem;
  }

  .background-icons {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 1;
    perspective: 80rem;

    .foreground-icon,
    .background-icon {
      position: absolute;
      color: $color-white--100;
      display: none;

      transform-style: preserve-3d;
    }

    .background-icon {
      @include media-from(sm) {
        display: block;
      }
    }

    .foreground-icon {
      filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.08))
        drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.08));

      @include media-from(md) {
        display: block;
      }
    }

    .carreer {
      left: 3.57575rem;
      top: 5.624rem;
      transform: rotate(15deg);
    }

    .battery {
      left: 12.8125rem;
      top: 11.41288rem;
      transform: rotate(-15deg);
    }

    .lightning {
      right: 2.4935rem;
      top: 7.49769rem;
      transform: rotate(5.097deg);
    }

    .sun {
      right: 15.387rem;
      top: 15.762rem;
      transform: rotate(-4.497deg);
    }

    .solar-plant {
      left: 10.67988rem;
      top: 3.11738rem;
      transform: rotate(3.938deg);
    }

    .emobility {
      left: 6.27781rem;
      top: 14.65281rem;
      transform: rotate(-6.782deg);
    }

    .heart {
      right: 9.08rem;
      top: 9.0175rem;
      transform: rotate(-7.122deg);
    }

    .wind-power {
      right: 3.35188rem;
      top: 15.14538rem;
      transform: rotate(8.41deg);
    }

    .rocket-icon {
      position: absolute;
      right: 3rem;
      top: -6rem;
      transform-style: preserve-3d;
    }

    .plant-leaf-icon {
      display: none;
      position: absolute;
      left: 2rem;
      top: -6rem;

      transform-style: preserve-3d;

      @include media-from(sm) {
        display: block;
      }

      @include media-from(md) {
        left: -5rem;
        top: 4rem;
      }
    }
  }

  .headline {
    color: $color-white--100;

    text-shadow: $shadow--small;
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 0.833;
    margin: 0;

    text-align: center;
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    row-gap: 0.75rem;
    width: 100%;
    max-width: 45rem;
    z-index: 2;

    @include media-from(sm) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      column-gap: 0.75rem;
    }
  }

  .input {
    background-color: $color-white--100;
    border-radius: 0.5rem;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    grid-column: span 2;

    @include media-from(sm) {
      grid-column: span 1;
    }

    input,
    select {
      all: unset;
      width: 100%;
      height: 3.625rem;

      padding: 0 1rem;

      &,
      &::placeholder {
        color: $color-black--100;
        font-size: 1.0625rem;
        font-weight: 400;
      }
    }

    input {
      padding-left: 3.5rem;
    }

    select {
      display: flex;
      align-items: center;
    }

    .icon {
      color: $color-surrogate--100;
      height: 1.5rem;
      width: 1.5rem;
    }

    .input-icon,
    .select-icon {
      position: absolute;
      pointer-events: none;
    }

    .input-icon {
      left: 1rem;
    }

    .select-icon {
      right: 1rem;
    }
  }

  .search-input {
    grid-column: span 2;
  }
}

.jobs {
  .amount {
    color: $color-black--100;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.2;
    margin: 0;
  }

  .job {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 1.5rem;
    border-radius: 0.75rem;
    border: 1.5px solid $color-black--10;
    cursor: pointer;
    text-decoration: none;

    transition:
      border $duration-default,
      background $duration-default;

    &:hover {
      border: 1.5px solid $color-surrogate--100;
      background: $color-surrogate--03;
    }

    .title {
      color: $color-black--100;
      font-size: 1.25rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .infos {
      display: flex;
      flex-direction: row;
      gap: 1.5rem;
      align-items: center;

      .info {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        color: $color-black--100;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        text-transform: capitalize;

        .icon {
          color: $color-surrogate--100;
        }
      }
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  .paddle {
    all: unset;
    display: flex;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    color: $color-surrogate--100;
    padding: 0.5rem;

    transition: all $duration-default;

    &:not(:disabled):hover {
      border: 1px solid $color-surrogate--100;
      background-color: $color-surrogate--03;
    }

    &:disabled {
      cursor: not-allowed;
      color: $color-surrogate--10;
    }
  }

  .text {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.33;
  }
}
