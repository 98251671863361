@import '../../styles/index';

.wrapper {
  display: flex;
  max-width: $container-size-default;
  width: 100%;
  margin: auto;
  flex-direction: column;

  &.mobile-right-column-first {
    flex-direction: column-reverse;
  }

  &.hide-left-column {
    @include media-to(sm) {
      .left {
        display: none;
      }

      .right {
        width: 100%;
      }
    }
  }

  &.hide-right-column {
    @include media-to(sm) {
      .right {
        display: none;
      }

      .left {
        width: 100%;
      }
    }
  }

  @include media-to(sm) {
    padding-bottom: 0.5rem;
  }

  @include media-from(sm) {
    width: unset;
    &,
    &.mobile-right-column-first {
      flex-direction: row;
    }
    &:not(:last-child) {
      padding-bottom: 2rem;
    }
  }
}

.side {
  display: flex;
  flex-direction: column;
  position: relative;

  @include media-to(sm) {
    width: 100% !important;
  }

  &.children-space-default {
    @include space-between-elements();
  }

  &.children-space-none {
    @include space-between-elements(0);
  }

  &.children-space-small {
    @include space-between-elements(3rem);
  }

  &.children-space-tiny {
    @include space-between-elements(2rem);
  }

  &.children-space-micro {
    @include space-between-elements(1rem);
  }

  &.left {
    @include media-to(sm) {
      .wrapper:not(.mobile-right-column-first) & {
        margin-bottom: 2rem;
      }
      .mobile-right-column-first & {
        margin-top: 2rem;
      }
    }

    @include media-from(sm) {
      padding-right: 1rem;
    }
  }

  &.right {
    @include media-from(sm) {
      padding-left: 1rem;
    }
  }

  @include media-from(sm) {
    &.align-top {
      justify-content: flex-start;
    }

    &.align-center {
      justify-content: center;
    }

    &.align-bottom {
      justify-content: flex-end;
    }
  }

  &.separator {
    @include media-to(sm) {
      .mobile-right-column-first & {
        border-top: 1px solid $color-black--20;
        padding-top: 2rem;
        margin-top: 2rem;
      }

      .wrapper:not(.mobile-right-column-first) & {
        border-bottom: 1px solid $color-black--20;
        padding-bottom: 2rem;
        margin-top: 3rem;
        margin-bottom: 3.5rem;
      }
    }

    @include media-from(sm) {
      border-right: 1px solid $color-black--20;
      padding-right: 2rem;

      & + .side {
        padding-left: 2rem;
      }
    }
  }
}

.justified {
  &-left {
    margin-left: auto;

    @include media-from(md) {
      margin-left: calc((100% - #{$container-size-small}) / 2);
      max-width: calc(100% - ((100% - #{$container-size-small}) / 2));
    }
  }

  &-right {
    margin-right: auto;

    @include media-from(md) {
      margin-right: calc((100% - #{$container-size-small}) / 2);
      max-width: calc(100% - ((100% - #{$container-size-small}) / 2));
    }
  }
}
