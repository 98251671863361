@import '../../styles/index';

.requestConsentContainer {
  align-items: center;
  border: 1px solid $color-surrogate--20;
  border-radius: 1.5rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;

  &::before {
    background-image: url(./maps_background.webp);
    background-position: center;
    background-size: cover;
    content: '';
    filter: blur(10px) brightness(0.7);
    inset: -20px;
    position: absolute;
  }

  @include media-from(xs) {
    border: none;
    padding: 1.25rem;
  }

  .requestConsent {
    background-color: $color-white--100;
    border: 1px solid $color-surrogate--10;
    border-radius: 1.5rem;
    max-width: 37.125rem;
    overflow: auto;
    position: relative;
    width: 100%;
    z-index: 1;

    @include media-to(xs) {
      border: none;
      border-radius: 0;
      height: 100%;
    }

    p {
      margin: 0;
    }

    .top {
      background-color: $color-surrogate--03;
      border-bottom: 1px solid $color-surrogate--10;
      padding: 2rem 2rem 1.5rem 2rem;

      .title {
        font-size: $font-size-22;
        font-weight: 500;
        line-height: 1.27;
        @extend %text-linear-gradient;

        margin-block: 0 0.5rem;
      }

      p {
        font-size: $font-size-14;
        line-height: 1.43;

        @include media-from(sm) {
          font-size: $font-size-16;
          line-height: 1.5;
        }
      }
    }

    .bottom {
      padding: 1.5rem 2rem 2rem 2rem;

      p {
        color: $color-black--40;
        font-size: $font-size-14;
        line-height: 1.43;
        margin-bottom: 24px;

        a {
          color: inherit;
          display: block;
          text-decoration: underline;
        }
      }

      .actionButton {
        font-size: $font-size-14;
        height: 2.5rem;
        min-width: unset;
      }
    }

    .scrollButtonWrapper {
      bottom: 0;
      position: sticky;

      .scrollButton {
        bottom: 1.125rem;
        position: absolute;
        right: 1.25rem;
      }
    }
  }
}
