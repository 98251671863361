@import '../../styles/index';

.base {
  background-color: $color-white--100;
  isolation: isolate;
  overflow: hidden;
  position: relative;

  .background-layer {
    inset: 0;
    position: absolute;
    z-index: -1;
  }

  .surface-layer {
    height: 100%;
  }
}
