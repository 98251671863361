@import '../../styles/index';

%reset-button {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: visible;

  background: transparent;

  color: inherit;
  font: inherit;

  line-height: normal;
}

.base {
  @extend %reset-button;

  border-radius: 6px;
  background-color: $color-white--100;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08), 0 8px 16px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
  height: 5rem;
  transition: box-shadow $duration-short, color $duration-default,
    background $duration-default, transform $duration-default;

  color: $color-surrogate--100;
  font-size: $font-size-18;
  font-weight: 500;
  line-height: 1.75rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus {
    box-shadow: 0 1px 3px 0 rgba(199, 18, 92, 0.12),
      0 8px 16px 0 rgba(199, 18, 92, 0.12), 0 0 0 4px rgba(199, 18, 92, 0.12);
    outline: none;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-4px);
  }

  &.inactive,
  &:disabled {
    border: 2px solid $color-surrogate--20;
    border-radius: 6px;
    color: $color-surrogate--40;
    box-shadow: none;
    transform: translateY(0);
    cursor: default;
  }

  @include media-from(sm) {
    padding: 1.375rem 1.125rem;
    height: unset;
  }
}

.icon {
  margin-left: 1rem;
}

.base-text {
  text-align: left;
}

.sub-text {
  color: $color-surrogate--40;
  font-weight: $font-weight-regular;
}

.text-wrapper {
  display: flex;
  flex-wrap: wrap;
}
