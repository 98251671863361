@import '../../styles/index';

.base {
  @extend %box-shadow-hoverable;
  align-items: center;
  background-color: $color-white--100;
  border: none;
  border-radius: 50%;
  display: flex;
  height: 3.75rem;
  justify-content: center;
  padding: 0;
  transition: transform $duration-default;
  width: 3.75rem;

  & > i,
  .text {
    color: $color-surrogate--100;
    flex-shrink: 0;
  }

  & > .text {
    font-size: 1.875rem;
    font-weight: $font-weight-regular;
    position: relative;
    top: -0.125rem;
    user-select: none;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border-radius: 50%;
    box-shadow:
      0 1px 3px 0 rgba(199, 18, 92, 0.12),
      0 8px 16px 0 rgba(199, 18, 92, 0.12),
      0 0 0 4px rgba(199, 18, 92, 0.12);
    outline: none;
  }

  &.inverse {
    background-color: $color-surrogate--100;

    & > i,
    .text {
      color: $color-white--100;
    }
  }

  &.inactive {
    & > i,
    .text {
      color: $color-surrogate--20;
    }

    &:focus {
      box-shadow: 0 0 0 4px rgba(0, 2, 16, 0.04);
    }
  }

  &:disabled {
    background-color: $color-black--20;
    border: none;
    cursor: auto;
    transform: none;
    transition: none;

    &::before,
    &::after {
      box-shadow: none;
    }

    & > i,
    .text {
      color: $color-surrogate--20;
    }
  }
}

.small {
  height: 1.875rem;
  width: 1.875rem;

  & > i {
    height: 0.875rem;
    width: 0.875rem;
  }
}

.medium {
  height: 3.125rem;
  width: 3.125rem;

  & > i {
    height: 1rem;
    width: 1rem;
  }
}

.large {
  height: 3.75rem;
  width: 3.75rem;

  & > i {
    height: 1.125rem;
    width: 1.125rem;
  }
}
