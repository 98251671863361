@import '../../styles/index';

.base {
  height: 6.625rem;
  width: 6.625rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;

  img {
    border-radius: 50%;
  }

  @include media-from(sm) {
    height: 11rem;
    width: 11rem;
  }
}

.border {
  border: 0.125rem solid $color-black--20;
}

.shadow {
  box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.12);
}
